<template>
  <div>
    <FullScreenDialog
      v-model="dialog"
      without-padding
      title="Ordini"
      :route-father="routeFather"
    >
      <template v-slot:header-actions>
        <ExportExcelTable
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      > </ExportExcelTable>
        <v-btn text link @click="gotToNew" :disabled="isLicenseBlocked">
          <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
        </v-btn>
      </template>

      <template v-slot:toolbar-extension> </template>
      <template v-slot:content>
        <div class="d-flex pa-5" style="width: 100%">
          <AdvancedSearch
            placeholder="Cerca Ordini"
            :filters="orderListFilter"
            @apply="applyFilters"
          ></AdvancedSearch>
        </div>
        <div style="height: 100%; overflow-y: hidden" ref="dataTableWrapper">
          <OrderDataTable
            :show-select="false"
            v-model="selected"
            :headers="headers"
            :items="filteredOrders"
            :loading="loading"
            item-key="id"
            loading-text="Caricamento ordini"
            :translator="translator"
            fixed-header
            :show-actions="true"
            @delete="deleteOrder"
            @edit="editOrder"
            :height="dataTableHeight"
            :page.sync="page"
            :row-per-page.sync="rowPerPage"
            :total-pages.sync="totalPages"
            @dblclick:row="editOrderDoubleClick"
          >
            <template v-slot:actions="{ item }">
              <div style="min-width: 100px">
                <v-tooltip
                  allow-overflow
                  bottom
                  v-if="item.status != 'fulfilled' && item.status != 'rejected'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="ml-1"
                      v-on="on"
                      v-bind="attrs"
                      @click="editOrder(item)"
                    >
                      <v-icon medium> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Modifica Ordine</span>
                </v-tooltip>
                <v-tooltip
                  allow-overflow
                  bottom
                  v-if="item.status == 'created'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="ml-1"
                      v-on="on"
                      v-bind="attrs"
                      @click="showDeleteOrder(item)"
                    >
                      <v-icon medium> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>Elimina Ordine</span>
                </v-tooltip>
                <v-tooltip
                  allow-overflow
                  bottom
                  v-if="item.status == 'fulfilled' || item.status == 'rejected'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="ml-1"
                      v-on="on"
                      v-bind="attrs"
                      @click="editOrder(item)"
                    >
                      <v-icon medium> mdi-clipboard-list </v-icon>
                    </v-btn>
                  </template>
                  <span>Riepilogo Ordine</span>
                </v-tooltip>
                <v-tooltip allow-overflow bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="!!item.supplier"
                      icon
                      class="ml-1"
                      v-on="on"
                      v-bind="attrs"
                      @click="printOrder(item)"
                    >
                      <v-icon medium> mdi-printer </v-icon>
                    </v-btn>
                  </template>
                  <span>Stampa Riepilogo Ordine</span>
                </v-tooltip>
                <v-tooltip
                  allow-overflow
                  bottom
                  v-if="
                    item.status == 'fulfilled' ||
                    item.status == 'rejected' ||
                    item.status == 'pending'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.supplier"
                      icon
                      class="ml-1"
                      v-on="on"
                      v-bind="attrs"
                      @click="detailMovement(item)"
                    >
                      <v-icon medium> mdi-package-up </v-icon>
                    </v-btn>
                  </template>
                  <span>Riepilogo Movimenti</span>
                </v-tooltip>
                <v-tooltip
                  allow-overflow
                  bottom
                  v-if="
                    (item.status == 'fulfilled' || item.status == 'rejected') &&
                    !item.invoiceOrder[0] &&
                    !!item.supplier &&
                    !!currentUser && currentUser.system
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-on="on"
                      v-bind="attrs"
                      class="ml-1"
                      @click="showInvoiceCreation(item)"
                    >
                      <v-icon medium> mdi-receipt </v-icon>
                    </v-btn>
                  </template>
                  <span>Crea bozza</span>
                </v-tooltip>
                <v-tooltip
                  allow-overflow
                  bottom
                  v-if="
                    (item.status == 'fulfilled' || item.status == 'rejected') &&
                    item.invoiceOrder[0] &&
                    !!currentUser && currentUser.system
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-on="on"
                      v-bind="attrs"
                      class="ml-1"
                      @click="detailInvoice(item)"
                    >
                      <v-icon medium> mdi-receipt </v-icon>
                    </v-btn>
                  </template>
                  <span>Apri bozza</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:custom-amount="{ item }">
              <v-chip color="primary">
                {{ item["amount"] ? "€ " + item["amount"] : "-" }}</v-chip
              >
            </template>

            <template v-slot:custom-discount="{ item }">
              <v-chip color="grey lighten-4">
                {{
                  item["discount"] ? Number(item["discount"]).toFixed(0)+'%' : ""
                }}
                </v-chip
              >
            </template>
            <template v-slot:custom-status="{ item }">
              <v-tooltip v-if="item.status == 'created'" allow-overflow bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-on="on"
                    v-bind="attrs"
                    @click="updateStatus(item)"
                    color="E0E0E0"
                  >
                    mdi-package-up
                  </v-icon>
                </template>
                <span>Creato</span>
              </v-tooltip>

              <v-tooltip
                v-else-if="item.status == 'waiting'"
                allow-overflow
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="updateStatus(item)"
                    v-on="on"
                    v-bind="attrs"
                    color="yellow darken-3"
                  >
                    mdi-package-up
                  </v-icon>
                </template>
                <span>In Attesa</span>
              </v-tooltip>

              <v-tooltip
                v-else-if="item.status == 'pending'"
                allow-overflow
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-on="on"
                    v-bind="attrs"
                    @click="updateStatus(item)"
                    color="orange darken-3"
                  >
                    mdi-package-up
                  </v-icon>
                </template>
                <span>Evaso Parziale</span>
              </v-tooltip>

              <v-tooltip
                v-else-if="item.status == 'fulfilled'"
                allow-overflow
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="green darken-3"
                    v-on="on"
                    v-bind="attrs"
                    @click="updateStatus(item)"
                  >
                    mdi-package-up
                  </v-icon>
                </template>
                <span>Evaso Totale</span>
              </v-tooltip>
              <v-tooltip
                v-else-if="item.status == 'rejected'"
                allow-overflow
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="red darken-3"
                    v-on="on"
                    v-bind="attrs"
                    @click="updateStatus(item)"
                  >
                    mdi-package-up
                  </v-icon>
                </template>
                <span>Chiusura forzata</span>
              </v-tooltip>
            </template>
            <template v-slot:custom-invoicePresent="{ item }">
              <v-checkbox
                readonly
                :input-value="item.invoiceOrder.length > 0"
                hide-details="auto"
                dense
              ></v-checkbox>
            </template>

            <!-- <template v-slot:custom-invoiceCreatedAt="{ item }">
              <DateTimeFormattor
                :value="item.invoiceOrder.length >0 ? item.invoiceOrder[0].emittedAt : 'Non specificato'"
              ></DateTimeFormattor>
            </template> -->
          </OrderDataTable>
        </div>
      </template>
    </FullScreenDialog>

    <OrderUpdateDialog
      v-if="openDialogOrderUpdate"
      :order-id="orderToEditId"
      :open-dialog="openDialogOrderUpdate"
      :showRealQuantities="editBeforeFulfill"
      @close="closeOrderUpdateDialog"
      @reload-orders="reloadOrders"
    >
    </OrderUpdateDialog>

    <v-bottom-sheet v-if="openStepStatus" v-model="openStepStatus">
      <v-sheet class="text-center" height="200px">
        <v-btn text @click="updateStatus">Stato dell' ordine </v-btn>
        <div class="py-3">
          <StatusStepper v-model="step" @move-to="moveTo"> </StatusStepper>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <ConfirmDialog
      v-if="openSuccessDialog"
      :value="openSuccessDialog"
      @close="openSuccessDialog = false"
      dialog-width="360"
      dialog-height="calc(200px-10px)"
      persistent
      title="Elaborazione Stato Ordine"
    >
      <v-alert class="ma-2" dense outlined type="success">
        {{ message }}
        <template v-slot:footer>
          <v-spacer></v-spacer>
          <v-btn
            class="border-default-red"
            @click="openSuccessDialog = false"
            text
            color="error"
            >Chiudi</v-btn
          >
        </template>
      </v-alert>
    </ConfirmDialog>
    <ConfirmDialog
      v-if="openConfirmDialog"
      :value="openConfirmDialog"
      @close="openConfirmDialog = false"
      dialog-width="360"
      dialog-height="calc(200px-10px)"
      persistent
      title="Cambio Stato Ordine"
    >
      <v-alert class="ma-2" dense outlined type="warning">
        {{ message }}
      </v-alert>

      <template> </template>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn @click="openConfirmDialog = false" text color="error"
          >Chiudi</v-btn
        >
        <v-btn
          @click="elaborate"
          v-if="orderStatusSelected == 'created' && orderStatusTo === 'waiting'"
          text
          >Elabora</v-btn
        >
        <v-btn
          @click="fulfill"
          v-if="
            orderStatusSelected == 'waiting' && orderStatusTo === 'fulfilled'
          "
          text
          >Evadi</v-btn
        >
      </template>
    </ConfirmDialog>
    <ConfirmDialog
      v-if="showDeleteOrderDialog"
      v-model="showDeleteOrderDialog"
      @close="showDeleteOrderDialog = false"
      dialog-width="360"
      dialog-height="calc(200px-10px)"
      persistent
      title="Eliminazione Ordine"
    >
      <v-alert class="ma-2" dense outlined type="warning">
        Sicuro Di voler eliminare l'ordine, l'operazione sarà irreversibile.
      </v-alert>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn @click="showDeleteOrderDialog = false" text color="error"
          >Chiudi
        </v-btn>
        <v-btn @click="deleteOrder" text>Elimina </v-btn>
      </template>
    </ConfirmDialog>
    <ConfirmDialog
      v-if="showInvoiceCreationDialog"
      v-model="showInvoiceCreationDialog"
      @close="showInvoiceCreationDialog = false"
      dialog-width="360"
      dialog-height="calc(200px-10px)"
      persistent
      title="Generazione bozza"
    >
      <v-alert class="ma-2" dense outlined type="warning">
        Generare la bozza commerciale associata all'ordine?
      </v-alert>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn @click="showInvoiceCreationDialog = false" text color="error"
          >Chiudi
        </v-btn>
        <v-btn @click="createInvoice" text>Genera</v-btn>
      </template>
    </ConfirmDialog>
  </div>
</template>
<script>
import StatusStepper from "@/components/common/StatusStepper.vue";
import OrderUpdateDialog from "@/components/areas/warehouse/orders/new/OrderUpdateDialog.vue";
import OrderDataTable from "@/components/common/TypeDataTable";
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import { SearchBar, SimpleList } from "likablehair-ui-components";
import ConfirmDialog from "@/components/common/StandardDialog.vue";

import AdvancedSearch from "@/components/common/AdvancedSearch.vue";
import _orderListFilter from "./filters/orderList";
import itemVocabulary from "@/vocabulary/warehouse/item.vocabulary.js";

import orderService from "@/services/warehouse/orders.service.js";
import movementService from "@/services/warehouse/movements.service.js";
import currentUser from "@/services/currentUser.service";
import invoiceService from "@/services/invoices/invoices.service.js";
import invoiceVocabulary from "@/vocabulary/payments/invoice.vocabulary.js";

import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import dateUtils from "@/mixins/common/dateUtils"

export default {
  name: "OrdersList",
  components: {
    DateTimeFormattor,
    ConfirmDialog,
    StatusStepper,
    OrderUpdateDialog,
    OrderDataTable,
    FullScreenDialog,
    SearchBar,
    SimpleList,
    AdvancedSearch,
    ExportExcelTable
  },
  mixins: [dateUtils],
  data: function () {
    let props = this.$router.resolve({ name: "Warehouse" });

    return {
      userId: undefined,
      openSuccessDialog: false,
      message: undefined,
      openConfirmDialog: false,
      openStepStatus: false,
      dialog: true,
      checked: true,
      loading: false,
      orders: [],
      routeFather: props.href,
      selected: undefined,
      openDialogOrderUpdate: false,
      orderListFilter: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 100,
      dataTableHeightValue: 400,
      showDeleteOrderDialog: false,
      showInvoiceCreationDialog: false,
      orderToDelete: undefined,
      orderForInvoice: undefined,
      resizeListener: undefined,
      headers: [],
      orderStatusSelected: undefined,
      orderStatusTo: undefined,
      editBeforeFulfill: false,
      rightMenu: [
        {
          title: "Inserisci Ordine",
          icon: "mdi-tray-plus",
          routeName: "OrdersHome",
        },
      ],
      worksheetName: "",
      fileName:"",
      fieldsToExport: {},
      currentUser: undefined,
      isLicenseBlocked: undefined
    };
  },
  mounted: function () {
    this.setUser();

    this.orderListFilter = _orderListFilter;

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    };
    this.resizeListener = this.resizeListener.bind(this);

    this.fieldsToExport = orderService._fieldsToExport();
    this.worksheetName = "Export Ordini ";
    this.fileName =
      "Ordini_" + this._buildDateToExport(new Date()) +
      ".xls";

    window.addEventListener("resize", this.resizeListener);
    this.fetchHeaders();
    this.fetchOrders();
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.resizeListener);
  },
  methods: {
    openSupplierEdit() {
      this.openSuccessDialog = false;

      this.$router.push({
        name: "SuppliersRegistryEditFormTab",
        params: {
          id: this.supplier.id,
        },
        query: {
          pathToGoBack: this.$router.resolve({
            name: "OrderList",
          }).href,
        },
      });
    },
    createInvoice() {
      new Promise(async (resolve, reject) => {
        try {
          let order = this.orderForInvoice;

          if (!order || order.orderItems.length <= 0 || !order.supplier) {
            reject("order not define");
            return;
          }

          let items = order.orderItems.map((orderItem) => {
            return {
              id: orderItem.itemId,
              quantity: orderItem.qtyDelivered,
              price: orderItem.price,
            };
          });

          let supplier = {
            id: order.supplier.id,
          };
          let invoice = {
            amount: order.priceTotNetPurchase,
            description:
              "Bozza  dell' ordine " + order.code + ", " + order.description,
          };

          invoiceService
            .createFromOrder(invoice, supplier, order, items)
            .then((value) => {
              this.orderForInvoice = null;
              this.showInvoiceCreationDialog = false;
              this.closeOrderUpdateDialog("invoice_generated");
            })
            .catch((err) => {
              if (
                err.message === invoiceVocabulary.DataInvoice_NotPresent["key"]
              ) {
                this.$delegates.snackbar(
                  invoiceVocabulary.DataInvoice_NotPresent["desc"]
                );
              }
            });
          resolve();
        } catch (err) {
          console.log(err);
          reject("invoice not created");
        }
      });
    },
    detailMovement(order) {
      this.$router.push({
        name: "MovementOrderDetail",
        params: {
          orderId: order.id,
        },
        query: {
          pathToGoBack: this.$router.resolve({ name: "OrderList" }).href,
        },
      });
    },
    detailInvoice(order) {
      this.$router.push({
        name:
          order.invoiceOrder[0].invoice.status === "paid"
            ? "InvoicesDetailForm"
            : "InvoicesEditForm",
        params: {
          id: order.invoiceOrder[0].invoiceId,
        },
        query: {
          pathToGoBack: this.$router.resolve({ name: "OrderList" }).href,
          variant:
            order.invoiceOrder[0].invoice.status === "paid" ? "detail" : "edit",
        },
      });
    },
    updateStatus(item) {
      if (item) {
        this.orderStatusSelected = item.status;
        this.orderToEditId = item.id;
      }
      this.openStepStatus = !this.openStepStatus;
    },
    moveTo(statusTo) {
      if (this.orderStatusSelected != "fulfilled") {
        this.message = "";
        this.orderStatusTo = statusTo;
        let statusFrom = this.orderStatusSelected;
        this.openConfirmDialog = true;
        if (
          (statusFrom == "created" && statusTo == "fulfilled") ||
          (statusFrom == "pending" && statusTo != "fulfilled")
        )
          this.message = itemVocabulary.Order_ChangeStatus_Error.desc;
        else if (statusFrom == "waiting" && statusTo == "waiting")
          this.message = itemVocabulary.Order_Waiting.desc;
        if (statusTo == "waiting" && !this.message) this.editOrderinWaiting();
        else if (statusTo == "fulfilled" && !this.message)
          this.editOrderBeforeFulfill();
      }
    },
    setUser() {
      currentUser.getUser().then((user) => {
        this.userId = user.id;
      });
    },
    fulfill() {
      let itemsToUpload = [];
      orderService.getOrderItems(this.orderToEditId).then((results) => {
        let orderItems = results.rows;
        orderItems.map((orderItem) => {
          itemsToUpload.push({
            id: orderItem.itemId,
            qtyToUpload: orderItem.qtyToOrder, // revisionare dopo modifiche qtyDelivered
          });
        });

        movementService
          .uploadFromOrder(itemsToUpload, this.userId, this.orderToEditId)
          .then((movement) => {
            this.message = itemVocabulary.Upload_Complete.desc;
            this.openSuccessDialog = true;
            this.saveChangeStatus(this.orderStatusTo);
          })
          .catch((itemsElaborated) => {});
      });
    },
    elaborate() {
      this.saveChangeStatus(this.orderStatusTo);
    },
    saveChangeStatus(statusTo) {
      let endDate;
      if (statusTo == "fulfilled") endDate = new Date();
      orderService
        .updateOrder({
          id: this.orderToEditId,
          status: statusTo,
          endDate: endDate,
        })
        .then((value) => {
          this.openStepStatus = false;
          this.openConfirmDialog = false;
          this.message = "Elaborazione completata!";
          this.openSuccessDialog = true;
          this.reloadOrders();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    reloadOrders() {
      this.fetchOrders(this.currentFilters);
    },
    fetchOrders(filters) {
      this.loading = true;
      if (!!filters) this.correctFilters(filters);
      orderService.list(this.page, this.rowPerPage, filters).then((results) => {
        if (this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage;
        }

        if (this.page != results.page) {
          this.page = results.page;
        }

        this.totalPages = results.totalPages;
        if (this.totalPages < this.page) {
          this.page = this.totalPages;
        }
        this.orders = results.rows;
        this.loading = false;
      });
    },
    fetchHeaders() {
      orderService._fields().then((headers) => {
        this.headers = headers;
      });
    },
    gotToNew() {
      this.goTo("OrdersHome");
    },
    goTo(routeName) {
      this.$router.push({ name: routeName }).catch(() => {});
    },
    detailOrder(order) {
      this.openDialogOrderUpdate = true;
      this.orderToEditId = order.id;
    },
    editOrder(order) {
      this.openDialogOrderUpdate = true;
      this.orderToEditId = order.id;
    },
    editOrderDoubleClick(row, {item: order}) {
      this.openDialogOrderUpdate = true;
      this.orderToEditId = order.id;
    },
    translator(field, value) {
      if (field == "supplier" && value) {
        return value.businessName;
      }
    },
    applyFilters(filters) {
      this.currentFilters = filters;
      this.fetchOrders(filters);
    },
    closeOrderUpdateDialog(updateStatus) {
      if (!!updateStatus) this.fetchOrders(this.currentFilters);
      this.openDialogOrderUpdate = false;
      this.editBeforeFulfill = false;
      if (updateStatus == "fulfilled") {
        this.message = "Ordine evaso!";
        this.openSuccessDialog = true;
      } else if (updateStatus == "fulfilled_with_invoice") {
        this.message =
          "Ordine evaso e creata una bozza commerciale associata! Controlla in sezione Pagamenti!";
        this.openSuccessDialog = true;
      } else if (updateStatus == "invoice_generated") {
        this.message = "Creata la bozza associata!";
        this.openSuccessDialog = true;
      }
    },
    correctFilters(filters) {
      if (
        !!filters.statusWaiting ||
        !!filters.statusPending ||
        !!filters.statusCreated ||
        !!filters.statusFulfilled ||
        !!filters.statusRejected
      ) {
        filters.status = [];
        if (!!filters.statusCreated) filters.status.push("created");
        if (!!filters.statusWaiting) filters.status.push("waiting");
        if (!!filters.statusPending) filters.status.push("pending");
        if (!!filters.statusFulfilled) filters.status.push("fulfilled");
        if (!!filters.statusRejected) filters.status.push("rejected");
      } else filters.status = undefined;
    },
    printOrder(item) {
      orderService
        .print(item)
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    showDeleteOrder(order) {
      this.orderToDelete = order;
      this.showDeleteOrderDialog = true;
    },
    showInvoiceCreation(order) {
      this.orderForInvoice = order;
      this.showInvoiceCreationDialog = true;
    },
    editOrderBeforeFulfill() {
      this.openDialogOrderUpdate = true;
      this.editBeforeFulfill = true;
      this.openStepStatus = false;
      this.openConfirmDialog = false;
    },
    editOrderinWaiting() {
      this.openDialogOrderUpdate = true;
      this.editBeforeFulfill = false;
      this.openStepStatus = false;
      this.openConfirmDialog = false;
    },
    deleteOrder() {
      orderService
        .deleteOrder(this.orderToDelete)
        .then(() => {
          this.orderToDelete = undefined;
          this.fetchOrders();
          this.showDeleteOrderDialog = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchExcel(){
      //let filters = [...this.filtersValue];
      let filters = undefined;

      let orders = (await orderService.list(undefined, 15000, filters)).rows

      return orders
    },
  },
  computed: {
    step() {
      if (this.orderStatusSelected == "created") return 1;
      else if (this.orderStatusSelected == "waiting") return 2;
      else if (this.orderStatusSelected == "pending") return 2.5;
      else if (this.orderStatusSelected == "fulfilled") return 3;
      else if (this.orderStatusSelected == "rejected") return 0;
    },
    filteredOrders() {
      return this.orders;
    },
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  watch: {
    page() {
      this.fetchOrders(this.currentFilters);
    },
    rowPerPage() {
      this.fetchOrders(this.currentFilters);
    },
  },
  currentUser: {
    bind: 'currentUser'
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>

<style>
.border-default-red {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #ee7d7d;
}
</style>