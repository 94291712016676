<template>
  <div
    class="border-default mt-4 d-flex flex-column"
    :class="{
      'justify-center align-center': centerTitle,
    }"
  >
    {{ description }}
    <slot name="content">
      <div
        class="d-flex flex-column"
        :class="{
          'justify-center align-center': centerContent,
        }"
      >
        <div
          class="d-flex flex-column"
          :class="{
            'text-h4': !small,
            'text-h6': small,
            'justify-center align-center': centerValue,
          }"
        >
          {{ localValue }}
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "BorderedDivComponent",
  data() {
    return {
      localValue: this.value,
    };
  },
  mounted() {},
  props: {
    value: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    small: {
      type: Boolean,
      default: false,
    },
    centerTitle: {
      type: Boolean,
      default: true,
    },
    centerBody: {
      type: Boolean,
      default: true,
    },
    centerValue: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value(newVal) {
      this.localValue = newVal;
    },
  },
};
</script>

<style>
.border-default {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #DCD8D8;
}
</style>