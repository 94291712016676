import suppliersService from "@/services/suppliers/suppliers.service";

export default [
  {
    name: "code",
    type: "string",
    text: "Codice Interno",
    icon: "mdi-code-tags",
  },
  {
    name: "description",
    type: "string",
    text: "Descrizione",
    icon: "mdi-clipboard-text",
  },
  {
    name: "supplierId",
    type: "object",
    text: "Fornitori",
    icon: "mdi-account-multiple",
    fieldToShow: "businessName",
    fetcher: suppliersService.list,
  },
  {
    name: "statusCreated",
    type: "boolean",
    text: "Ordini Aperti",
    icon: "",
    value: false
  },
  {
    name: "statusWaiting",
    type: "boolean",
    text: "Ordini in Attesa",
    value: false,
    icon: "",
  },
  {
    name: "statusPending",
    type: "boolean",
    text: "Ordini Parzialmente Evasi",
    icon: "",
    value: false
  },
  {
    name: "statusFulfilled",
    type: "boolean",
    text: "Ordini Evasi",
    icon: "",
    value: false
  },
  {
    name: "statusRejected",
    type: "boolean",
    text: "Ordini Chiusi",
    icon: "",
    value: false
  },
  {
    name: "createdAt",
    type: "date",
    text: "Data Creazione",
    icon: "mdi-calendar",
  },
];
