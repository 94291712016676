<template>
  <div class="text-center">
    <v-autocomplete
      :dense="dense"
      filled
      :items="itemsWithOther"
      :item-text="itemText"
      :item-value="itemValue"
      :prepend-icon="icon"
      :label="listName"
      :multiple="multiple"
      v-model="localValue"
      return-object
      @change="otherItemCheck"
      :hide-details="hideDetails"
      :chips="chips"
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
    ></v-autocomplete>
    <StandardDialog
      v-model="dialog"
      :title="listName"
      dialog-max-width="600px"
      :dialog-height="null"
    >
      <v-alert type="warning" v-if="showAlert" dense class="mt-2">
        {{ errorMessage }}
      </v-alert>
      <v-form ref="form" v-on:submit.prevent>  
        <v-text-field
          class="mt-2"
          v-model="newItem.name"
          :label="listName"
          :rules="rules"
          hide-details="auto"
          dense
          filled
        ></v-text-field>
      </v-form>
      <template v-slot:footer-actions>
        <v-btn text color="error" v-on:click="cancelNewItem()"> Annulla </v-btn>
        <v-btn text color="default"  v-on:click="confirmNewItem()">
          Conferma
        </v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "ComboAdder",
  components: {
    StandardDialog,
  },
  data: function () {
    return {
      rules: [
        (value) => !!value || "Richiesto.",
        (value) => (value && value.length >= 4) || "Minimo 4 caratteri",
        (value) => (value && value.length <= 40) || "Massimo 40 caratteri",
      ],
      dialog: false,
      localValue: {},
      keyOther: uuidv4(),
      newItem: {
        name: "",
      },
      localItems: this.items,
      showAlert: false,
      errorMessage: "",
      tempLocalValue: undefined,
    };
  },
  props: {
    items: {
      type: Array,
    },
    dense:{
      type: Boolean,
      default: true,
    },
    chips:{
      type:Boolean,
      default:false,
    },
    smallChips:{
      type:Boolean,
      default:false,
    },
    deletableChips:{
      type:Boolean,
      default:false,
    },
    multiple:{
      type: Boolean,
      default: false,
    },
    fetcher: {
      default: undefined,
    },
    creator: {
      default: undefined,
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    value: {},
    returnObject: {
      type: Boolean,
      default: false,
    },
    listName: {
      type: String,
      default: "Elemento",
    },
    icon: {
      type: String,
    },
    hideDetails: {
      default: 'auto'
    },
    validator: {
      default: function () {
        let validateFunction = function (value) {
          if (!!value.name && value.name.length >= 4) return true;
          else return "Elemento non valido";
        };
        return validateFunction;
      },
    },
  },
  mounted: function () {
    this.loadItems().then(() => {
      if(!this.multiple){
        if (this.returnObject){
          this.localValue = this.value
          this.tempLocalValue = this.value
        }
        else{
          this.localValue[this.itemValue] = this.value
          this.tempLocalValue = this.value
        }
      }
      else
        this.localValue = this.value
    })
  },
  methods: {
    otherItemCheck(newSelected) {
      if(newSelected == null){
        this.$emit("input", null)
        return
      }
      if(this.multiple){
        if(newSelected.length>0 && newSelected[newSelected.length-1].id === this.keyOther) {
          this.dialog = true
          newSelected.pop()
        }
        else{
          if (this.returnObject) this.$emit("input", newSelected);
          else this.$emit("input", newSelected);
        }
      }
      else{
        if (newSelected[this.itemValue] === this.keyOther) this.dialog = true;
        else {
          if (this.returnObject) this.$emit("input", newSelected);
          else this.$emit("input", newSelected[this.itemValue]);
        }
      }
    },
    confirmNewItem() {
      const valid = this.validate();
      const isUnique = this.isUnique(this.newItem);

      if (valid === true && isUnique) {
        if (!!this.creator) {
          this.creator(this.newItem).then((createdItem) => {
            this.localItems.push(createdItem)
            this.$emit("new-item", createdItem)
            if (this.returnObject) this.$emit("input", createdItem)
            else {
              if(!this.multiple)
                this.$emit("input", createdItem[this.itemValue])
              else{
                this.localValue.push(createdItem)
                this.$emit("input",this.localValue)
              }
              }
          });
        }
        else this.$emit("new-item", this.newItem);
        this.dialog = false;
      } else {
        if (!isUnique) this.errorMessage = this.listName + " già presente";
        else this.errorMessage = valid;
        this.showAlert = true;
      }
    },
    cancelNewItem() {
      this.localValue = this.value;
      this.dialog = false;
      this.showAlert = false;
      this.$refs.form.reset();
    },
    loadItems() {
      return new Promise((resolve, reject) => {
        if (!this.fetcher) resolve();
  
        this.fetcher().then((items) => {
          this.localItems = items;
          resolve(items)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    isUnique(newItem) {
      for (let i = 0; i < this.localItems.length; i++) {
        if (
          this.localItems[i][this.itemValue] === newItem[this.itemValue] ||
          this.localItems[i][this.itemText] === newItem[this.itemText]
        )
          return false;
      }
      return true;
    },
    validate() {
      if (!!this.validator) return this.validator(this.newItem);
      else return true;
    },
  },
  watch: {
    items(newVal) {
      this.localItems = newVal;
    },
    dialog(newVal){
      if(newVal==false && this.localValue[this.itemValue]==this.keyOther && !this.multiple){
        this.localValue = this.tempLocalValue
        this.newItem.name = ''
      }
      if(!!this.multiple && newVal == false)
        this.newItem.name = ''
        
    },
    value(newVal) {
      if (this.returnObject) this.localValue = newVal;
      else {
        let obj = {};
        if(!this.multiple){
          obj[this.itemValue] = newVal;
          this.localValue = obj;
          this.tempLocalValue = newVal
        }
        else
          this.localValue = newVal
      }
    },
  },
  computed: {
    itemsWithOther() {
      if (!!this.localItems) {
        let obj = {}
        obj[this.itemText] = "Altro"
        obj[this.itemValue] = this.keyOther
        return [...this.localItems, obj]
      } else return []
    },
    getLocalValue(){
      return this.localValue
    }
  },
};
</script>

<style scoped>
</style>