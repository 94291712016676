<template>
  <div class="d-flex flex-wrap justify-center aling-center" width="100vw">
    <v-stepper>
      <v-stepper-header v-if="stepFocused == 2.5">
        <v-stepper-step
          color="#E0E0E0"
          :complete="stepFocused >= 1"
          step="1"
        >
          Creato
        </v-stepper-step>
        <v-stepper-step
          color="yellow darken-3"
          @click="moveIn('waiting')"
          :complete="stepFocused >= 2"
          step="2"
        >
          In Attesa
        </v-stepper-step>
        <v-stepper-step
          color="orange darken-3"
          :complete="stepFocused == 2.5"
          step="3"
        >
          Parzialmente Evaso
        </v-stepper-step>
        <v-stepper-step
          color="green darken-3"
          @click="moveIn('fulfilled')"
          :complete="stepFocused == 3"
          step="4"
        >
          Evaso
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-header v-else-if="stepFocused == 0">
        <v-stepper-step
          color="red darken-3"
          :complete="stepFocused == 0"
          step="1"
        >
          Ordine Cancellato
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-header v-else>
        <v-stepper-step
          color="#E0E0E0"
          :complete="stepFocused >= 1"
          step="1"
        >
          Creato
        </v-stepper-step>
        <v-stepper-step
          color="yellow darken-3"
          @click="moveIn('waiting')"
          :complete="stepFocused >= 2"
          step="2"
        >
          In Attesa
        </v-stepper-step>
        <v-stepper-step
          color="green darken-3"
          @click="moveIn('fulfilled')"
          :complete="stepFocused == 3"
          step="3"
        >
          Evaso
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-icon v-if="stepFocused == 0" large color="red darken-3"
            >mdi-package-up</v-icon
          >
          <v-icon v-else-if="stepFocused == 1" large color="#E0E0E0"
            >mdi-package-up</v-icon
          >
          <v-icon v-else-if="stepFocused == 2" large color="yellow darken-3"
            >mdi-package-up</v-icon
          >
          <v-icon v-else-if="stepFocused == 2.5" large color="orange darken-3"
            >mdi-package-up</v-icon
          >
          <v-icon v-else-if="stepFocused == 3" large color="green darken-3"
            >mdi-package-up</v-icon
          >
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>


  </div>
</template>

<script>
import Vue from 'vue'
export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
    bus: {
      type: Object,
      default:() => new Vue(),
    },
  },
  data() {
    return {
      stepFocused: this.value,
    };
  },
  methods: {
    moveIn(status) {
      this.$emit('move-to', status)
    },
  },
};
</script>

<style>
</style>