<template>
  <div>
    <StandardDialog
      v-model="elementListDialog"
      persistent
      :title="title"
      dialog-height="calc(90vh-1)"
      dialog-width="80vw"
    >
      <div style="height: 100%; overflow: hidden" ref="dataTableWrapper">
        <v-subheader class="font-italic font-weight-light py-1"
          >Inserisci descrizione e ammontare</v-subheader
        >
        <v-row class="row-items-bottom">
          <v-col>
            Descrizione
            <v-text-field
              v-model="description"
              class="border-default"
              flat
              solo
              filled
              hide-details="auto"
              label="Descrizione"
              :rules="[presenceRule]"
            ></v-text-field>
          </v-col>
          <v-col>
            Quantità
            <v-text-field
              v-model="quantity"
              class="border-default"
              flat
              solo
              filled
              type="number"
              hide-details="auto"
              label="Ammontare"
              :rules="[presenceRule]"
            ></v-text-field>
          </v-col>
          <v-col>
            Prezzo unitario
            <v-text-field
              v-model="price"
              class="border-default"
              flat
              solo
              filled
              type="number"
              hide-details="auto"
              label="Ammontare"
              :rules="[presenceRule]"
            ></v-text-field>
          </v-col>
          <v-col>
            Totale
            <v-text-field
              :value="priceTot"
              class="border-default"
              flat
              solo
              disabled
              filled
              type="number"
              hide-details="auto"
              label="Ammontare"
              :rules="[presenceRule]"
            ></v-text-field>
          </v-col>
          <v-col v-if="!hideTagsInfos">
            Tag Abbinato
            <TagsAutocomplete
              v-model="tags"
              dense
              return-object
              :rules="[presenceRule]"
              class="border-default"
              :multiple="false"
              :filters="{typeCost:false}"
            ></TagsAutocomplete>  
          </v-col> 
        </v-row>
      </div>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text class="border-default-red" color="error" @click="close">Chiudi</v-btn>
        <v-btn text class="border-default" @click="addGenericElement" :disabled="formValid">Aggiungi</v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog";
import TagsAutocomplete from "@/components/common/TagsAutocomplete.vue";

export default {
  name: "GenericElementListDialog",
  components: {
    StandardDialog,
    TagsAutocomplete,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Aggiungi elemento personalizzato",
    },
    hideTagsInfos: {
      type: Boolean,
      default: false
    }
  },
  mounted() {},

  data() {
    return {
      elementListDialog: this.openDialog,
      price: 0,
      description: "",
      quantity: 0,
      tags: undefined,
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
    };
  },
  computed: {
    priceTot() {
      let total = Number(this.price) * Number(this.quantity);
      return Number(total).toFixed(2);
    },
    formValid() {
      return (
        !this.description ||
        !this.quantity ||
        !this.price ||
        Number(this.price) <= 0 ||
        Number(this.quantity) <= 0 ||
        (!this.tags && !this.hideTagsInfos)
      );
    },
  },
  methods: {
    close() {
      this.elementListDialog = false;
      this.$emit("close");
    },
    addGenericElement() {
      let genericElement = {
        price: this.price,
        description: this.description,
        quantity: this.quantity,
        tags: this.tags,
      };
      this.$emit("add-element", genericElement);
      this.close();
    },
  },
  watch: {
    openDialog(newVal) {
      this.elementListDialog = newVal;
    },
  },
};
</script>

<style>
  .border-default {
    padding: 5px;
    border-radius: 25px;
    border: 1px solid #dcd8d8;
  } 
  .border-default-red {
    padding: 5px;
    border-radius: 25px;
    border: 1px solid #ee7d7d;
  }
  .row-items-bottom {
    align-items: end;
  }
</style>